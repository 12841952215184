<!--Footer Starts-->
<footer class="footer d-flex justify-content-around">
  <img src="https://applicatifs.rhprofiler.fr/templaterh/images/logo.png" alt="logo" id="footerlogo" name="footerlogo" width="112" height="auto">
  <p class="copyright clearfix m-0">
    Copyright&copy; 1997 - {{currentDate | date: 'yyyy'}} / ACTIVITAE EMPLOI /
    <a id="pixinventLink" href="https://rhprofiler.com/">Solution RHPROFILER</a>
    <span class="d-none d-sm-inline-block">, Tous droits réservés.</span>
  </p>
  <img src="https://applicatifs.rhprofiler.fr/templaterhv2/images/logo_activitae_footer.png" alt="logo" id="footerlogobis" name="footerlogobis" width="112" height="auto">
</footer>
<!--Footer Ends-->
