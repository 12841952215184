import { Injectable } from '@angular/core';

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {

  recognition: any;
  public isStoppedSpeechRecog = true;
  public text = '';
  tempWords;

  constructor() { }

  init() {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.interimResults = true;
    this.recognition.lang = 'fr-FR';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      console.log(transcript);
    });
  }

  public isChrome() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return false;
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return false;
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return true;
      case agent.indexOf('trident') > -1:
        return false;
      case agent.indexOf('firefox') > -1:
        return false;
      case agent.indexOf('safari') > -1:
        return false;
      default:
        return false;
    }
  }

  start() {
    if (!this.isStoppedSpeechRecog)
      return;
    this.text = '';
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    console.log("Speech recognition started")
    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
        console.log("End speech recognition")
      } else {
        this.wordConcat()
        this.recognition.start();
      }
    });
  }
  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat()
    this.recognition.stop();
    console.log("End speech recognition")
  }

  wordConcat() {
    if(this.text)
      this.text = this.text + '' + this.tempWords;
    else
      this.text = this.tempWords;
    this.tempWords = '';
  }


}
