import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appIframeClick]'
})
export class IframeClickDirective {

  constructor(el: ElementRef) {
    el.nativeElement.contents().find('html').bind('click', function () {
      
    });
  }
}
