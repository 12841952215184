'use strict';

import { Injectable } from '@angular/core';
@Injectable()
export class VariablesGlobales {
  /** Sous Menu **/
  public password: string = '';
  public login: string = '';
  public uid: string = '';
  public iframeUrl: string;

  /** Thème boolean **/
  public homeCardBack: boolean = false;
  public isRestrictPerimetre = false;

  /** Variables globals **/
  public countToDo = 0;

  constructor() {
    if (!(<any>window).myService) {
      (<any>window).myService = this;
    }
    return (<any>window).myService;
  }
}
