import { Component, OnInit, ElementRef, Renderer2, OnDestroy, Inject } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { fromEvent, Subscription, timer } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';
import { EncryptDecryptService } from '../services/EncryptDecryptService';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MsgResult } from '../models/msgResult';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {

  layoutSub: Subscription;
  isOpen = false;

  isDarkTheme : boolean = false;
  isCardBack = false;
  isRestrictPerimetre = false;

  baseUrl: string;

  public todo: Array<any> = [
    {
      name: 'Recrutement',
      items: []
    },
    {
      name: 'Actions',
      items: []
    },
  ];
  infos: any;

  ngOnInit() {
    let array = Object.getOwnPropertyNames(AuthService.user.todolist.categories.Actions.items);
    for (var i = 0; i < array.length; i++) {
      this.todo.find(el => el && el.name === 'Actions').items.push(AuthService.user.todolist.categories.Actions.items[array[i]]);
    }
    array = Object.getOwnPropertyNames(AuthService.user.todolist.categories.Recrutement.items);
    for (var i = 0; i < array.length; i++) {
      this.todo.find(el => el && el.name === 'Recrutement').items.push(AuthService.user.todolist.categories.Recrutement.items[array[i]]);
    }
    this.todo.forEach(x => x.items.sort((el, ele) => el?.order - ele?.order));
  }

  changeRestrictPerimetre() {
    (<any>window).myService.isRestrictPerimetre = this.isRestrictPerimetre;
    this.http.get(this.baseUrl + 'config/util_onboarding/' + this.isRestrictPerimetre + '/' + AuthService.user.recId).subscribe(result => {
      //console.log(result);
    });
    this.router.navigateByUrl('/page/home');
  }

  changeBackCardHome() {
    localStorage.setItem('cardback', JSON.stringify(this.isCardBack));
    (<any>window).myService.homeCardBack = this.isCardBack;
  }

  public clicked = {
    'one': false,
    'two': false,
    'three': false,
    'four': false,
    'five': false,
    'six': false,
    'seven': false
  };

  public onclick(key: any) {
    this.clicked[key] = true;
    setTimeout(() => {
      this.clicked[key] = false;
    }, 2000);
  }

  EventsClick(e, u) {
    if (e && u) {
      const mouseup$ = fromEvent(e.target, 'mouseup');

      const clickTimer$ = timer(200);
      mouseup$.pipe(takeUntil(clickTimer$)).subscribe(e => this.onClicked(u));
    }
  }

  onClicked(path: string): void {
    event.preventDefault();
    if (path.includes('applicatifs.rhprofiler.fr')) {
      (<any>window).myService.iframeUrl = path;
      if (this.router.url !== '/page/iframe') {
        this.router.navigate(['/page/iframe']);
      } else {
        (document.getElementById('oulaal') as HTMLIFrameElement).src = path;
      }
      this.spinner.show('homeSpinner',
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
    } else {
      this.router.navigate([path]);
    }
  }

  goHome() {
    if (this.router.url !== '/page/home')
      this.router.navigate(['/page/home']);
  }

  intervalId = setInterval(() => this.updateNotif(), 30000);

  MANY_ITEMS = 'MANY_ITEMS';
  BAG = "DRAGULA_EVENTS";
  subs = new Subscription();
  subsNgFor = new Subscription();
  constructor(private layoutService: LayoutService, private configService: ConfigService,
    private router: Router,
    private encdec: EncryptDecryptService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    @Inject('BASE_URL') baseUrl: string) {
    // Capture pour accès à l'API privé
    this.baseUrl = baseUrl;
    this.layoutSub = layoutService.toggleNotiSidebar$.subscribe(
      open => {
        this.isOpen = open;
      });
    this.isRestrictPerimetre = (<any>window).myService.isRestrictPerimetre;
    this.updateNotif();

    this.isDarkTheme = JSON.parse(localStorage.getItem('darkmode'));
    this.isCardBack = JSON.parse(localStorage.getItem('cardback'));
    (<any>window).myService.homeCardBack = this.isCardBack;
  }

  getCount(cat) {
    let i = 0;
    if(this.infos && this.todo)
      this.todo.find(x => x.name === cat)?.items.forEach(x => {
        if (x)
          i += this.infos[x.name];
      });
    return i;
  }

  isAdmin(): boolean {
    return AuthService.user.niveau <= 1;
  }

  updateNotif() {
    this.http.get('https://api.sitederecrutement.com/Home/GetToDoInfos?apikey=' + AuthService.user.apikey + '&idutil=' + AuthService.user.recId + '&restriction=' + (<any>window).myService.isRestrictPerimetre, { responseType: "text" }).subscribe(result => {
      if (result.includes('{&quot;Error&quot;:')) {
        console.error(result);
      }
      else {
        const obj = JSON.parse(this.encdec.getDecrypt(result));
        //console.log(obj);
        this.infos = obj;
        let i = 0;
        
        for (var prop in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            if (prop === 'repnegHome' || prop === 'convocHome' || prop === 'otherHome' || prop === 'aucunHome') {
              obj[prop].forEach(x => {
                if (x && x.count)
                  i += parseInt(x.count);
              });
            } else {
              if ((AuthService.user.todolist.categories.Recrutement.items[prop]?.right && AuthService.user.todolist.categories.Recrutement.items[prop]?.view) || (AuthService.user.todolist.categories.Actions.items[prop]?.right && AuthService.user.todolist.categories.Actions.items[prop]?.view))
                i += parseInt(obj[prop]);
            }
          }
        }
        
        (<any>window).myService.countToDo = i;
      }
    }, error => console.error(error));
  }

  getValidInfos(name: string) {
    if (this.infos)
      return this.infos[name]?.filter(x => x.count > 0);
    else
      return undefined;
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

  darked(): void {
    localStorage.setItem('darkmode', JSON.stringify(this.isDarkTheme));
    if (this.isDarkTheme) {
      this.configService.applyTemplateConfigChange(this.configService.setConfigValueDark());
    } else {
      this.configService.applyTemplateConfigChange(this.configService.setConfigValue());
    }
  }

}
