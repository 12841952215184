<!-- Sidebar Header starts -->
<div (mouseenter)="isOver = true" (mouseleave)="isOver = false" class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a class="logo-text float-left">
      <div class="logo-img">
        <img style="width: 100%; max-height: 90px;" [src]="getLogo()" alt=""/>
      </div>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
      (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-arrow-right': config.layout.sidebar.collapsed,
        'ft-arrow-left': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div (mouseenter)="isOver = true" (mouseleave)="isOver = false" class="sidebar-content main-menu-content" [perfectScrollbar]  [disabled]="!perfectScrollbarEnable">
  <div class="nav-container">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.title" [path]="menuItem.path" level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle href="javascript:;" *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink && isRight(menuItem.hasRight)">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a appSidebarAnchorToggle href="javascript:;" (click)="onClicked(menuItem.path)"
          *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink && isRight(menuItem.hasRight)">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != ''" [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a href="javascript:;" (click)="onClicked(menuItem.path)" *ngIf="menuItem.isExternalLink && isRight(menuItem.hasRight)">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
         <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0 ">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title" [hasSub]="menuSubItem.class.includes('has-sub') ? true: false"
          [path]="menuSubItem.path" level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
            <a href="javascript:;" appSidebarAnchorToggle (click)="onClicked(menuSubItem.path)" *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink && isRight(menuSubItem.hasRight)">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a href="javascript:;" appSidebarAnchorToggle (click)="onClicked(menuSubItem.path)"
              *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink && isRight(menuSubItem.hasRight)">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a href="javascript:;" *ngIf="menuSubItem.isExternalLink && isRight(menuSubItem.hasRight)" (click)="onClicked(menuSubItem.path)">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <!-- Third level menu -->
            <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
              <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title" [hasSub]="false"
              [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                <a placement="top" [ngbTooltip]="menuSubsubItem.title | translate" href="javascript:;" appSidebarAnchorToggle (click)="onClicked(menuSubsubItem.path)"
                  *ngIf="(!menuSubsubItem.isExternalLink || externalSubSubLinkBlock) && isRight(menuSubsubItem.hasRight)">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                    [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a placement="top" [ngbTooltip]="menuSubsubItem.title | translate" href="javascript:;" (click)="onClicked(menuSubsubItem.path)">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
