import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Observable, of, pipe, Subject, Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { CustomizerService } from '../services/customizer.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { VoiceRecognitionService } from '../services/voice-recognition.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { EncryptDecryptService } from '../services/EncryptDecryptService';
import { debounceTime } from 'rxjs/operators';

// ball-climbing-dot + 

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [VoiceRecognitionService]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";

  toggleClass = "ft-maximize";
  toggleClass2 = "icon-size-fullscreen";

  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  account: any;

  voiceService: VoiceRecognitionService;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();

  infos: any;

  searchString = '';

  intervalId = setInterval(() => this.updateNotif(), 30000);

  public config: any = {};

  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService, private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private injector: Injector,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private encdec: EncryptDecryptService  ) {
    this.updateNotif();
    this.voiceService = this.injector.get(VoiceRecognitionService);

    if (this.voiceService.isChrome()) {
      this.voiceService.init();
    }

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  updateNotif() {
    this.http.get('https://api.sitederecrutement.com/Home/GetNotifsInfos?apikey=' + AuthService.user.apikey + '&idutil=' + AuthService.user.recId + '&restriction=' + (<any>window).myService.isRestrictPerimetre, { responseType: "text" }).subscribe(result => {
      if (result.includes('{&quot;Error&quot;:')) {
        console.error(result);
      }
      else {
        const obj = JSON.parse(this.encdec.getDecrypt(result));
        this.infos = obj;
      }
    }, error => console.error(error))
  }

  onTestResult(path: string, idcentraltest: string): void {
    this.http.get('https://api.sitederecrutement.com/Home/UpdateTestViewed?apikey=' + sessionStorage.getItem('api') + '&idcentraltest=' + idcentraltest, { responseType: "text" }).subscribe(result => {
    }, error => console.error(error))
    window.open(path, '_blank');
  }

  onClicked(path: string): void {
    event.preventDefault();
    if (path.includes('applicatifs.rhprofiler.fr')) {
      (<any>window).myService.iframeUrl = path;
      if (this.router.url !== '/page/iframe') {
        this.router.navigate(['/page/iframe']);
      } else {
        (document.getElementById('oulaal') as HTMLIFrameElement).src = path;
      }
      this.spinner.show('homeSpinner',
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
    } else {
      this.router.navigate([path]);
    }
  }

  logOut() {
    localStorage.removeItem('api');
    this.auth.logout();
  }

  getLogo(): string  {
    return AuthService.db;
  }



  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.intervalId)
      clearInterval(this.intervalId);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    this.account = AuthService.user;

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/RHProLogo.png';
    }
    else {
      this.logoUrl = 'assets/img/RHProLogo.png';
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }
  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    var path = '';
    path = 'https://applicatifs.rhprofiler.fr/groupe/rech_candidats/rech_cand_offre.asp?nommail=' + this.searchString + '&val=tout&valider=OK';
    event.preventDefault();
      (<any>window).myService.iframeUrl = path;
      if (this.router.url !== '/page/iframe') {
        this.router.navigate(['/page/iframe']);
      } else {
        (document.getElementById('oulaal') as HTMLIFrameElement).src = path;
        this.spinner.show('homeSpinner',
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
    }
    this.searchString = '';
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
    //if (this.searchResults && this.searchResults.length > 0) {
    //  let url = this.searchResults.first.url;
    //  if (url && url != '') {
    //    this.control.setValue("");
    //    this.searchOpenClass = '';
    //    this.router.navigate([url]);
    //    this.seachTextEmpty.emit(true);
    //  }
    //}
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  savOpen(): boolean {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var hour = new Date().getHours();
    var day = new Date().getDay();
    var year = new Date().getFullYear();
    var result = true;


    this.JoursFeries(year).forEach(x => {
      x.setHours(0, 0, 0, 0);
      if (x.getTime() === date.getTime())
        result = false;
    });
    if (day >= 1 && day <= 4 && (!(hour > 8 && hour < 12) && !(hour > 13 && hour < 18)))
      return false;
    if (day == 5 && (!(hour > 8 && hour < 12) && !(hour > 12 && hour < 17)))
      return false;
    return result;
  }

  JoursFeries(an) {
  var JourAn = new Date(an, 0, 1)
  var FeteTravail = new Date(an, 4, 1)
  var Victoire1945 = new Date(an, 4, 8)
  var FeteNationale = new Date(an, 6, 14)
  var Assomption = new Date(an, 7, 15)
  var Toussaint = new Date(an, 10, 1)
  var Armistice = new Date(an, 10, 11)
  var Noel = new Date(an, 11, 25)

  var G = an % 19
  var C = Math.floor(an / 100)
  var H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30
  var I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11))
  var J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7
  var L = I - J
  var MoisPaques = 3 + Math.floor((L + 40) / 44)
  var JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4)
  var LundiPaques = new Date(an, MoisPaques - 1, JourPaques + 1)
  var Ascension = new Date(an, MoisPaques - 1, JourPaques + 39)
  var Pentecote = new Date(an, MoisPaques - 1, JourPaques + 49)
  var LundiPentecote = new Date(an, MoisPaques - 1, JourPaques + 50)

    return new Array(JourAn, LundiPaques, FeteTravail, Victoire1945, Ascension, Pentecote, LundiPentecote, FeteNationale, Assomption, Toussaint, Armistice, Noel)
}

  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    }
    else if (language === 'es') {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    }
    else if (language === 'pt') {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    }
    else if (language === 'de') {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
      this.toggleClass2 = 'icon-size-actual';
    } else {
      this.toggleClass = "ft-maximize";
      this.toggleClass2 = 'icon-size-fullscreen';
    }
  }

  startVoice() {
    event.stopPropagation();
    this.voiceService.start();
  }

  stopVoice() {
    event.stopPropagation();
    this.voiceService.stop();
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        //this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  getCountToDo(): number {
    return (<any>window).myService.countToDo;
  }

  getCountNotifs(): number {
    return this.infos?.cands?.length + this.infos?.tests?.length + this.infos?.drs?.length + this.infos?.retours?.length;
  }

  getCountNotifsD(): number {
    return this.infos?.candsD?.length + this.infos?.testsD?.length /*+ this.infos?.drsD?.length + this.infos?.retoursD?.length*/;
  }
}
