import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { VariablesGlobales } from './shared/services/variables.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './shared/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import * as shajs from 'sha.js';
import { MsgResult } from './shared/models/msgResult';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy  {
  errorMsg = '';
  baseUrl = '';
  subscription: Subscription;
  changePasswordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)]),
    retypeNewPassword: new FormControl('', [Validators.required])
  });

  @ViewChild('desutil', { static: false }) desutil: ElementRef;

  constructor(private router: Router, private params: VariablesGlobales, private modalService: NgbModal, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (AuthService.passDate && new Date().getTime() - AuthService.passDate > 1.051e+10)
          this.open(this.desutil);
      }
    });
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
        .subscribe(() => window.scrollTo(0, 0));
      //this.router.navigate(['/page/home'])
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

  open(content) {
    if (this.isForcePass())
      this.changePasswordForm.get("oldPassword").setValue(AuthService.user.pass);
    this.modalService.open(content, {
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
      windowClass: 'transparent-modal'
    }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  get cpf() {
    return this.changePasswordForm.controls;
  }

  onChangePasswordFormSubmit() {
    if (this.changePasswordForm.invalid) {
      return;
    }

    let data = {
      id: AuthService.user._id,
      oldPass: this.isForcePass() ? this.cpf.oldPassword.value : shajs('sha256').update(this.cpf.oldPassword.value).digest('hex'),
      pass: shajs('sha256').update(this.cpf.newPassword.value).digest('hex'),
      recid: AuthService.user.recId
    };

    this.http.post<MsgResult>(this.baseUrl + 'password/', data).subscribe(result => {
      if (!result.result) {
        this.open(this.desutil);
        this.errorMsg = result.error;
        console.error(result.error);
      } else {
        AuthService.user.pass = shajs('sha256').update(this.cpf.newPassword.value).digest('hex');
        AuthService.passDate = result.data;
      }
    });
  }

  isForcePass() {
    return AuthService.forcePass;
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  viewpass(password, eye): void {
    if (password.type === 'text') {
      password.type = 'password';
      eye.classList.add('ft-eye');
      eye.classList.remove('ft-eye-off');
    } else {
      password.type = 'text';
      eye.classList.remove('ft-eye');
      eye.classList.add('ft-eye-off');
    }
  }

  // This function is used in open
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
