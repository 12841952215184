import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mailer',
  templateUrl: './mailer.component.html',
  styleUrls: ['./mailer.component.scss']
})
export class MailerComponent implements OnInit {

  @Input() item = ''; // decorate the property with @Input()

  constructor() { }

  ngOnInit(): void {
  }

}
