import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsgResult } from '../models/msgResult';

@Component({
  selector: 'app-cands',
  templateUrl: './cands.component.html',
  styleUrls: ['./cands.component.scss']
})
export class CandsComponent implements OnInit {
  @Input() annonce: any;
  @Input() nonLue: boolean;

  baseUrl: string;
  active = 2;

  public cands: any[] = [];
  public candSelected: any;
  public candsSelected: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient) {
    this.baseUrl = baseUrl;
    spinner.show('listCands', {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: false
    });
  }

  ngOnInit(): void {
    //console.log(this.annonce.idannonce  );
      this.http.get<MsgResult>(this.baseUrl + 'Candidats/offre/' + this.annonce.idannonce).subscribe(
        result => {
          if (!result.result) {
            console.error(result.error);
          } else {
            //console.log(result.data);
            this.cands = result.data;
            this.spinner.hide('listCands');
          }
        });
  }

  getActualRep(cand): any {
    return cand.candidatures.find(x => x.idAnnonce == this.annonce.idannonce);
  }

  setCand(cand) {
    event.stopPropagation();
    this.candSelected = cand;
  }

  removeCand(cand) {
    event.stopPropagation();
    this.candSelected = undefined;
  }

  clickCand(cand) {
    if (this.candsSelected.some(x => x.idcandidat == cand.idcandidat))
      this.candsSelected = this.candsSelected.filter((x) => { return x.idcandidat != cand.idcandidat });
    else
      this.candsSelected.push(cand);
  }

  verifCand(cand) : boolean {
    return this.candsSelected.some(x => x.idcandidat == cand.idcandidat);
  }

  verifArrays(item, need) : boolean {
    return item.some((x) => x.includes(need));
  }

  verifString(item, need): boolean {
    return item.includes(need);
  }
}
