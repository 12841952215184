<!-- START Notification Sidebar-->
<aside class="notification-sidebar d-none d-sm-none d-md-block" id="notification-sidebar" [ngClass]="{open: isOpen}">
  <a class="notification-sidebar-close" (click)="onClose()">
    <i class="ft-x font-medium-3 grey darken-1"></i>
  </a>
  <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
    <div class="row">
      <div class="col-12 notification-tab-content">
        <nav ngbNav #nav="ngbNav" class="nav-tabs text-info">
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink class="nav-link">To Do List</a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="activity-tab" role="tabpanel" aria-expanded="true"
                aria-labelledby="base-tab1">
                <div class="col-12" id="activity">
                  <div *ngFor="let cat of todo; let index = index">
                    <div *ngIf="getCount(cat.name) > 0">
                      <h5 class="my-2 text-bold-500">{{cat.name}}</h5>
                      <div class="timeline-left timeline-wrapper mb-3" [id]="'timeline-' + (index + 1)">
                        <ul class="timeline">
                          <li class="timeline-line mt-4"></li>
                          <ng-template ngFor let-item [ngForOf]="cat.items" let-i="index">
                            <li [id]="item.name" *ngIf="infos && item && infos[item.name] > 0 && item.view && item.right" (mousedown)="EventsClick($event, item.url)" style="cursor: pointer;" class="timeline-item">
                              <div class="timeline-badge">
                                <span [class]="item.color" data-toggle="tooltip" data-placement="right">
                                  <i [class]="item.icon"></i>
                                </span>
                              </div>
                              <div class="activity-list-text">
                                <h6 class="">
                                  <span *ngIf="infos && infos[item.name] > 1">{{infos[item.name]}} {{item.title}}</span>
                                  <span *ngIf="infos && infos[item.name] <= 1">{{infos[item.name]}} {{item.titleSing}}</span>
                                  <span class="float-right grey font-italic font-small-2"></span>
                                </h6>
                              </div>
                            </li>
                          </ng-template>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h5 class="my-2 text-bold-500">Etats</h5>
                  <div class="timeline-left timeline-wrapper" id="timeline-3">
                    <ul class="timeline">
                      <li class="timeline-line mt-4"></li>
                      <li (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/source_etat_v6.asp?numpage=1&etat=' + repnep.idetat)" *ngFor="let repnep of getValidInfos('repnegHome')" style="cursor: pointer;" class="timeline-item">
                        <div class="timeline-badge">
                          <span class="bg-success">
                            <i class="ft-thumbs-down white"></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="">
                            <span>{{repnep.count}} - {{repnep.etat_intitule}}</span>
                            <span class="float-right grey font-italic font-small-2"></span>
                          </h6>
                        </div>
                      </li>
                      <li (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/source_etat_v6.asp?numpage=1&etat=' + repnep.idetat)" *ngFor="let repnep of getValidInfos('convocHome')" style="cursor: pointer;" class="timeline-item">
                        <div class="timeline-badge">
                          <span class="bg-success">
                            <i class="ft-phone-call white"></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="">
                            <span>{{repnep.count}} - {{repnep.etat_intitule}}</span>
                            <span class="float-right grey font-italic font-small-2"></span>
                          </h6>
                        </div>
                      </li>
                      <li (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/source_etat_v6.asp?numpage=1&etat=' + repnep.idetat)" *ngFor="let repnep of getValidInfos('otherHome')" style="cursor: pointer;" class="timeline-item">
                        <div class="timeline-badge">
                          <span class="bg-success">
                            <i class="ft-git-branch white"></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="">
                            <span>{{repnep.count}} - {{repnep.etat_intitule}}</span>
                            <span class="float-right grey font-italic font-small-2"></span>
                          </h6>
                        </div>
                      </li>
                      <li (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/source_etat_v6.asp?numpage=1&etat=' + repnep.idetat)" *ngFor="let repnep of getValidInfos('aucunHome')" style="cursor: pointer;" class="timeline-item">
                        <div class="timeline-badge">
                          <span class="bg-success">
                            <i class="ft-hash white"></i>
                          </span>
                        </div>
                        <div class="activity-list-text">
                          <h6 class="">
                            <span>{{repnep.count}} - {{repnep.etat_intitule}}</span>
                            <span class="float-right grey font-italic font-small-2"></span>
                          </h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
           </ng-container>
          <ng-container ngbNavItem class="nav-item">
            <a ngbNavLink (click)="goHome()" class="nav-link">Configuration</a>
            <ng-template ngbNavContent>
              <div class="row tab-pane" id="settings-tab" aria-labelledby="base-tab2">
                <div class="col-12" id="settings">
                  <h5 class="mt-2 mb-3">Paramètres générales</h5>
                  <ul class="list-unstyled mb-0 mx-2">
                    <li class="mb-3">
                      <div class="mb-1">
                        <span class="text-bold-500">Thème Sombre / Clair</span>
                        <div class="float-right">
                          <div class="custom-switch">
                            <input [(ngModel)]="isDarkTheme" (change)="darked()" class="custom-control-input" id="noti-s-switch-1" type="checkbox">
                            <label class="custom-control-label" for="noti-s-switch-1"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Bascule le thème entre le mode clair et sombre</p>
                    </li>
                    <li class="mb-3">
                      <div class="mb-1">
                        <span class="text-bold-500">Home Card Style</span>
                        <div class="float-right">
                          <div class="custom-switch">
                            <input [(ngModel)]="isCardBack" (change)="changeBackCardHome()" class="custom-control-input" id="noti-s-switch-2" type="checkbox">
                            <label class="custom-control-label" for="noti-s-switch-2"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Bascule entre les cartes colorées ou non</p>
                    </li>
                    <li [hidden]="!isAdmin()" class="mb-3">
                      <div class="mb-1">
                        <span class="text-bold-500">Restriction Périmètre</span>
                        <div class="float-right">
                          <div class="custom-switch">
                            <input [(ngModel)]="isRestrictPerimetre" (ngModelChange)="changeRestrictPerimetre()" class="custom-control-input" id="noti-s-switch-3" type="checkbox">
                            <label class="custom-control-label" for="noti-s-switch-3"></label>
                          </div>
                        </div>
                      </div>
                      <p class="font-small-3 m-0">Bascule entre tous les périmètres et uniquement le vôtre</p>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
           </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar-->
