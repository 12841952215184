<router-outlet></router-outlet>

<ng-template #desutil let-c="close" let-d="dismiss">
  <div class="col-12">
    <div class="card text-center">
      <div class="card-header">
        <h4 class="card-title danger">Protection : changement de mot de passe</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <p class="card-text mb-3">Par mesure de protection votre mot de passe a expiré, pour accéder de nouveau à votre espace de recrutement veuillez le modifier.</p>
          <p class="mb-2 text-danger text-center" *ngIf="errorMsg"><b>{{ errorMsg }}</b></p>
          <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePasswordFormSubmit()">
            <div [hidden]="isForcePass()" class="form-group ">
              <label for="old-password">Ancien mot de passe</label>
              <div class="controls position-relative has-icon-right-error">
                <input #password type="password" formControlName="oldPassword" class="form-control" placeholder="Ancien"
                       [class]="{ 'is-invalid': cpf.oldPassword.invalid, 'is-valid': !cpf.oldPassword.invalid }"
                       required>
                <div class="form-control-position">
                  <i #eye (click)="viewpass(password, eye)" class="ft-eye cursor-pointer"></i>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="new-password">Nouveau mot de passe</label>
              <div class="controls position-relative has-icon-right-error">
                <input #password1 type="password" formControlName="newPassword" class="form-control" placeholder="Nouveau"
                       [class]="{ 'is-invalid': cpf.newPassword.invalid, 'is-valid': !cpf.newPassword.invalid }"
                       required>
                <div class="form-control-position">
                  <i #eye1 (click)="viewpass(password1, eye1)" class="ft-eye cursor-pointer"></i>
                </div>
                <div *ngIf="cpf.newPassword.invalid"
                     class="help-block mt-1 text-danger">
                  <i class="ft-alert-circle align-middle"></i> Votre nouveau mot de passe doit contenir : 8 caractères minimum, 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial (@$!%*?&)
                </div>
              </div>
            </div>
            <app-password-strength-bar [passwordToCheck]="cpf.newPassword.value" [barLabel]="'Niveau de sécurité'">
            </app-password-strength-bar>
            <div class="form-group">
              <label for="retype-new-password">Confirmation du mot de passe</label>
              <div class="controls position-relative has-icon-right-error">
                <input #password2 type="password" (paste)="onPaste($event)" formControlName="retypeNewPassword" class="form-control"
                       placeholder="Confirmation"
                       [class]="{ 'is-invalid': cpf.newPassword.value != cpf.retypeNewPassword.value || cpf.retypeNewPassword.invalid, 'is-valid': !cpf.retypeNewPassword.invalid && cpf.newPassword.value == cpf.retypeNewPassword.value }"
                       required>
                <div class="form-control-position">
                  <i #eye2 (click)="viewpass(password2, eye2)" class="ft-eye cursor-pointer"></i>
                </div>
              </div>
            </div>
            <div class="d-flex flex-sm-row flex-column justify-content-end">
              <button type="submit" [disabled]="cpf.newPassword.value != cpf.retypeNewPassword.value || changePasswordForm.invalid" class="ml-1" [class]="cpf.newPassword.value != cpf.retypeNewPassword.value || changePasswordForm.invalid ? 'btn btn-secondary mr-sm-2 mb-1 disabled' : 'btn btn-secondary mr-sm-2 mb-1'" (click)="d('Close click')"><i class="fa fa-check text-success mr-1"></i> Valider</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--<div class="modal-backdrop fade show"></div>
<div class="panel">
  <div class="row">
    <div class="col-12">
      Panel Works !
    </div>
  </div>
</div>-->
