<div [matchHeight]="'card'" [candidat]="candSelected" class="row">
  <div *ngFor="let cand of cands" [class]="candSelected?.idcandidat == cand?.idcandidat ? 'col-12' : 'col-md-6 col-12'">
    <div (click)="clickCand(cand)"
         [ngClass]="verifCand(cand) ? 'selected' : ''"
         class="card card-cand"
         [style]="candSelected ? 'overflow: hidden;' : 'cursor: pointer; overflow: hidden;'">
      <div class="card-header">
        <div class="card-title">
          <div class="row">
            <div class="col-12 col-sm-10">
              <div class="media candidature">
                <div class="users-avatar-shadow avatar avatar-lg mr-3 rounded-circle bg-primary">
                  <div class="avatar-content">{{ cand.prenom.substr(0,1).toUpperCase() }}{{ cand.nom.substr(0,1).toUpperCase() }}</div>
                </div>
                <div class="media-body">
                  <span>{{ cand.prenom }} {{ cand.nom }}</span>
                  <div class="d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2">
                    <span>
                      <i class="ft-shield mr-1" placement="bottom" ngbTooltip="Consentement"></i>
                      <i class="ft-users mr-1" placement="bottom" ngbTooltip="Doublon"></i>
                      <i class="fas fa-mask mr-1" placement="bottom" ngbTooltip="Anonyme"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-2 d-flex justify-content-end">
              <a [hidden]="candSelected && candSelected?.idcandidat == cand?.idcandidat" (click)="setCand(cand)" href="javaScript:void(0);" class=""><i class="ft-maximize"></i></a>
              <a [hidden]="!candSelected || candSelected?.idcandidat != cand?.idcandidat" (click)="removeCand(cand)" href="javaScript:void(0);" class=""><i class="ft-minimize"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="card-content">
          <div [hidden]="candSelected?.idcandidat == cand?.idcandidat" class="row">
            <div [hidden]="getActualRep(cand)?.sources.length <= 0" class="col-12 mt-1">
              <h4 class="font-medium-1 mb-0">{{ getActualRep(cand)?.sources.join(', ') }}</h4>
              <p class="grey font-small-3 m-0">Sources</p>
            </div>
            <div [hidden]="getActualRep(cand)?.date" class="col-6 mt-1">
              <h4 class="font-medium-1 mb-0">{{ getActualRep(cand)?.date | date: 'dd-MM-yyyy' }}</h4>
              <p class="grey font-small-3 m-0">Date candidature</p>
            </div>
            <div [hidden]="!cand.codePostal && !cand.ville" class="col-6 mt-1">
              <h4 class="font-medium-1 mb-0">{{ cand.codePostal?.substr(0,2) }} {{ cand.ville }}</h4>
              <p class="grey font-small-3 m-0">Ville</p>
            </div>
            <div [hidden]="!cand.formation" class="col-6 mt-1">
              <h4 [ngClass]="verifString(annonce.emp_niveau_formation, cand.formation) ? 'text-success' : ''" class="font-medium-1 mb-0">{{ cand.formation }}</h4>
              <p [hidden]="!cand.formationExt" class="grey font-small-3 m-0">{{ cand.formationExt }}</p>
              <p [hidden]="cand.formationExt" class="grey font-small-3 m-0">Formation</p>
            </div>
            <div [hidden]="!cand.experience" class="col-6 mt-1">
              <h4 class="font-medium-1 mb-0">{{ cand.experience }}</h4>
              <p class="grey font-small-3 m-0">Expérience</p>
            </div>
          </div>
          <div [hidden]="!candSelected || candSelected?.idcandidat != cand?.idcandidat" class="row">
            <div class="col-6">
              <nav ngbNav #navTd="ngbNav" class="nav-tabs nav-fill">
                <ng-container ngbNavItem>
                  <a ngbNavLink>Profil</a>
                  <ng-template ngbNavContent>
                    <div class="row border-bottom pb-2">
                      <div [hidden]="!cand.naissance" class="col-6 mt-1">
                        <h4 class="font-medium-1 mb-0">{{ cand.naissance | date: 'dd-MM-yyyy' }}</h4>
                        <p class="grey font-small-3 m-0">Date de naissance</p>
                      </div>
                      <div [hidden]="!cand.adresse && !cand.adresseComplement && !cand.codePostal && !cand.ville && !cand.pays" class="col-12 mt-1">
                        <a [href]="'https://www.google.com/maps/place/' + cand.adresse + ' ' + cand.adresseComplement + ',' + cand.codePostal + ' ' + cand.ville + ' ' + cand.pays" target="_blank">
                          <h4 class="font-medium-1 mb-0 text-info">
                            {{ cand.adresse }} {{ cand.adresseComplement }}, {{ cand.codePostal }} {{ cand.ville }}, {{ cand.pays }}
                          </h4>
                        </a>
                        <p class="grey font-small-3 m-0">Adresse</p>
                      </div>
                      <!--<div [hidden]="!cand.mail" class="col-12 mt-1">
                        <a [href]="'mailto:' + cand.mail">
                          <h4 class="font-medium-1 mb-0 text-info">
                            {{ cand.mail }}
                          </h4>
                        </a>
                        <p class="grey font-small-3 m-0">Adresse email</p>
                      </div>
                      <div [hidden]="!cand.mail2" class="col-12 mt-1">
                        <a [href]="'mailto:' + cand.mail2">
                          <h4 class="font-medium-1 mb-0 text-info">
                            {{ cand.mail2 }}
                          </h4>
                        </a>
                        <p class="grey font-small-3 m-0">Adresse email secondaire</p>
                      </div>-->
                      <div [hidden]="!cand.mobile" class="col-12 mt-1">
                        <a [href]="'tel:' + cand.mobile">
                          <h4 class="font-medium-1 mb-0 text-info">
                            {{ cand.mobile }}
                          </h4>
                        </a>
                        <p class="grey font-small-3 m-0">Téléphone mobile</p>
                      </div>
                      <div [hidden]="!cand.fixe" class="col-12 mt-1">
                        <a [href]="'tel:' + cand.fixe">
                          <h4 class="font-medium-1 mb-0 text-info">
                            {{ cand.fixe }}
                          </h4>
                        </a>
                        <p class="grey font-small-3 m-0">Téléphone fixe</p>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div [hidden]="!cand.experience" class="col-6">
                        <h4 class="font-medium-1 mb-0">
                          {{ cand.experience }}
                        </h4>
                        <p class="grey font-small-3 m-0">Expérience</p>
                      </div>
                      <div [hidden]="!cand.formation" class="col-6 mt-1">
                        <h4 [ngClass]="verifString(annonce.emp_niveau_formation, cand.formation) ? 'text-success' : ''" class="font-medium-1 mb-0">
                          {{ cand.formation }}
                        </h4>
                        <p [hidden]="!cand.formationExt" class="grey font-small-3 m-0">{{ cand.formationExt }}</p>
                        <p [hidden]="cand.formationExt" class="grey font-small-3 m-0">Formation</p>
                      </div>
                      <div [hidden]="!cand.statut" class="col-6 mt-1">
                        <h4 class="font-medium-1 mb-0">
                          {{ cand.statut }}
                        </h4>
                        <p class="grey font-small-3 m-0">Statut</p>
                      </div>
                      <div [hidden]="!cand.etude" class="col-6 mt-1">
                        <h4 class="font-medium-1 mb-0">
                          {{ cand.etude }}
                        </h4>
                        <p class="grey font-small-3 m-0">Etude</p>
                      </div>
                      <div [hidden]="!cand.etablissement" class="col-6 mt-1">
                        <h4 class="font-medium-1 mb-0">
                          {{ cand.etablissement }}
                        </h4>
                        <p [hidden]="!cand.etablissementExt" class="grey font-small-3 m-0">{{ cand.etablissementExt }}</p>
                        <p [hidden]="cand.etablissementExt" class="grey font-small-3 m-0">Établissement</p>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container ngbNavItem>
                  <a ngbNavLink>Five</a>
                  <ng-template ngbNavContent>Five ...</ng-template>
                </ng-container>
                <ng-container ngbNavItem>
                  <a ngbNavLink>Six</a>
                  <ng-template ngbNavContent>Six ...</ng-template>
                </ng-container>
              </nav>

              <div [ngbNavOutlet]="navTd" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner name="listCands"></ngx-spinner>
