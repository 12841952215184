<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item d-none d-lg-block mr-2 mt-1">
          <a placement="right" ngbTooltip="Plein écran" class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
             (click)="ToggleClass()">
              <i class=" {{toggleClass2}} font-medium-1"></i>
          </a>
        </li>
        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)">
            <i class="ft-search font-medium-3"></i>
          </a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [(ngModel)]="searchString" class="input" type="text" placeholder="Recherche par mots clés..." tabindex="0"
                   autofocus (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text no-cursor" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="" [src]="logoUrl"></div>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
               data-toggle="dropdown" ngbDropdownToggle>
              <i class="ft-bell font-medium-3"></i><span *ngIf="getCountNotifs() > 0" class="notification badge badge-pill badge-danger">{{getCountNotifs()}} </span>
            </a>
            <ul ngbDropdownMenu
                class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-danger">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i>
                    <span *ngIf="getCountNotifs() > 1" class="noti-title">
                      {{getCountNotifs()}} nouvelles
                      notifications
                    </span>
                    <span *ngIf="getCountNotifs() === 1" class="noti-title">
                      {{getCountNotifs()}} nouvelle
                      notification
                    </span>
                    <span *ngIf="getCountNotifs() <= 0" class="noti-title">
                      Aucune nouvelle notification
                    </span>
                  </div>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <div *ngIf="infos?.cands?.length > 0" class="cursor-pointer bg-success">
                  <div class="media d-flex align-items-center justify-content-center">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0 white">Candidatures</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between" (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/formulaire2/dossier_cand.asp?cand=' + info?.idrepform + '&pageprov=candidatnonlu&periode=hier&id=1&nb_pages=1,04&page=reponseemploi&idrecrut=' + info?.idrecrut + '&idannonce=' + info?.idannonce + '&idcandidat=' + info?.idcandidat)" href="javascript:void(0)" *ngFor="let info of infos?.cands">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar avatar-md bg-success mr-2">
                        <img *ngIf="info.photo" [src]="'https://fichiers.rhprofiler.fr/'+getLogo()+'/pj_supp/' + info?.photo" alt="avatar" />
                        <span *ngIf="!info.photo" class="avatar-content">{{info?.can_prenom?.substring(0, 1)}}{{info?.can_nom?.substring(0, 1)}}</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{info?.can_prenom}} {{info?.can_nom}}</span><small class="grey lighten-1 font-italic float-right">
                          {{info?.repform_date}}
                        </small>
                      </h6>
                      <small class="noti-text">a postulé sur l'offre ({{info?.ref}})</small>
                      <h6 class="noti-text font-small-3 m-0">
                        {{info?.intitule}}
                      </h6>
                    </div>
                  </div>
                </a>
                <div *ngIf="infos?.drs?.length > 0" class="cursor-pointer bg-danger">
                  <div class="media d-flex align-items-center justify-content-center">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0 white">Demande(s) rejetée(s)</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between" (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/modif.asp?choix=attente&num=' + info?.idannonce)" href="javascript:void(0)" *ngFor="let info of infos?.drs">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar avatar-md bg-danger mr-2">
                        <span *ngIf="!info.photo" class="avatar-content">
                          <i class="font-large-1 ft-slash white"></i>
                        </span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{info?.intitule}}</span><small class="grey lighten-1 font-italic float-right">
                          {{info?.date_passation}}
                        </small>
                      </h6>
                      <small class="noti-text">{{info?.ref}}</small>
                    </div>
                  </div>
                </a>
                <div *ngIf="infos?.tests?.length > 0" class="cursor-pointer bg-success">
                  <div class="media d-flex align-items-center justify-content-center">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0 white">Test(s) passé(s)</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between" (click)="info?.urlpdf ? onTestResult(info.urlpdf, info.idcentraltest) : onTestResult(info.urlhtml, info.idcentraltest)" href="javascript:void(0)" *ngFor="let info of infos?.tests">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar avatar-md bg-success mr-2">
                        <img *ngIf="info.photo" [src]="'https://fichiers.rhprofiler.fr/'+getLogo()+'/pj_supp/' + info?.photo" alt="avatar" />
                        <span *ngIf="!info.photo" class="avatar-content">{{info?.can_prenom?.substring(0, 1)}}{{info?.can_nom?.substring(0, 1)}}</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{info?.can_prenom}} {{info?.can_nom}}</span><small class="grey lighten-1 font-italic float-right">
                          {{info?.date_passation}}
                        </small>
                      </h6>
                      <small class="noti-text">a passé le test :</small>
                      <h6 class="noti-text font-small-3 m-0">
                        {{info?.test}}
                      </h6>
                    </div>
                  </div>
                </a>
                <div *ngIf="infos?.retours?.length > 0" class="cursor-pointer bg-info">
                  <div class="media d-flex align-items-center justify-content-center">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0 white">Retour(s)</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between" (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/formulaire2/dossier_cand.asp?cand='+info?.idrepform+'&pageprov=etat-v2_&id=0&nb_pages=1,22&page=reponseemploi&idrecrut='+info?.idrecrut+'&idannonce='+info?.idannonce+'&idcandidat='+info?.idcandidat+'&numpage=')" href="javascript:void(0)" *ngFor="let info of infos?.retours">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar avatar-md bg-info mr-2">
                        <img *ngIf="info.photo" [src]="'https://fichiers.rhprofiler.fr/'+getLogo()+'/pj_supp/' + info?.photo" alt="avatar" />
                        <span *ngIf="!info.photo" class="avatar-content">{{info?.can_prenom?.substring(0, 1)}}{{info?.can_nom?.substring(0, 1)}}</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{info?.can_prenom}} {{info?.can_nom}}</span><small class="grey lighten-1 font-italic float-right">
                          {{info?.retour_ope_date_avis}}
                        </small>
                      </h6>
                      <small class="noti-text">retour ope pour l'offre ({{info?.ref}}) </small>
                      <h6 class="noti-text font-small-3 m-0">
                        {{info?.intitule}}
                      </h6>
                    </div>
                  </div>
                </a>
                <div *ngIf="getCountNotifsD() > 0" class="cursor-pointer bg-grey">
                  <div class="media d-flex align-items-center justify-content-center">
                    <div class="media-left">
                      <div class="media-body">
                        <h6 class="m-0 white">Notifications déjà lues</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="d-flex justify-content-between" (click)="onClicked('https://applicatifs.rhprofiler.fr/groupe/emploi/formulaire2/dossier_cand.asp?cand=' + info?.idrepform + '&pageprov=candidatnonlu&periode=hier&id=1&nb_pages=1,04&page=reponseemploi&idrecrut=' + info?.idrecrut + '&idannonce=' + info?.idannonce + '&idcandidat=' + info?.idcandidat)" href="javascript:void(0)" *ngFor="let info of infos?.candsD">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar avatar-md bg-grey mr-2">
                        <img *ngIf="info.photo" [src]="'https://fichiers.rhprofiler.fr/'+getLogo()+'/pj_supp/' + info?.photo" alt="avatar" />
                        <span *ngIf="!info.photo" class="avatar-content">{{info?.can_prenom?.substring(0, 1)}}{{info?.can_nom?.substring(0, 1)}}</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{info?.can_prenom}} {{info?.can_nom}}</span><small class="grey lighten-1 font-italic float-right">
                          {{info?.repform_date}}
                        </small>
                      </h6>
                      <small class="noti-text">a postulé sur l'offre ({{info?.ref}})</small>
                      <h6 class="noti-text font-small-3 m-0">
                        {{info?.intitule}}
                      </h6>
                    </div>
                  </div>
                </a>
                <a class="d-flex justify-content-between" (click)="info?.urlpdf ? onTestResult(info.urlpdf, info.idcentraltest) : onTestResult(info.urlhtml, info.idcentraltest)" href="javascript:void(0)" *ngFor="let info of infos?.tests">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="avatar avatar-md bg-grey mr-2">
                        <img *ngIf="info.photo" [src]="'https://fichiers.rhprofiler.fr/'+getLogo()+'/pj_supp/' + info?.photo" alt="avatar" />
                        <span *ngIf="!info.photo" class="avatar-content">{{info?.can_prenom?.substring(0, 1)}}{{info?.can_nom?.substring(0, 1)}}</span>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0">
                        <span>{{info?.can_prenom}} {{info?.can_nom}}</span>
                        <small class="grey lighten-1 font-italic float-right">
                          {{info?.date_passation}}
                        </small>
                      </h6>
                      <small class="noti-text">a passé le test :</small>
                      <h6 class="noti-text font-small-3 m-0">
                        {{info?.test}}
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-center" id="dropdownBasic2"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2 ml-2">
                <span class="text-right"> {{account?.prenom}} {{account?.nom}}</span>
              </div>
              <div class="avatar bg-info mr-2">
                <img class="avatar-content" *ngIf="account?.photo" [src]="account?.photo" alt="avatar" />
                <span *ngIf="!account?.photo" class="avatar-content">{{account?.prenom.substring(0, 1)}}{{account?.nom.substring(0, 1)}}</span>
              </div>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
                 ngbDropdownMenu>
              <a class="dropdown-item" [routerLink]="['/pages/account-settings']">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Paramètres</span>
                </div>
              </a>
              <a class="dropdown-item" [routerLink]="['/pages/kb']">
                <div class="d-flex align-items-center">
                  <i class="ft-book mr-2"></i><span>Documentation</span>
                </div>
              </a>
              <a class="dropdown-item" [class.disabled]="!savOpen()" href="tel:+33562473030">
                <div class="d-flex align-items-center">
                  <i [class]="savOpen() ? 'ft-phone-call mr-2' : 'ft-phone-off mr-2'"></i>
                  <span *ngIf="savOpen()">+33 562473030</span>
                  <span *ngIf="!savOpen()">Support Fermé</span>
                </div>
              </a>
              <a class="dropdown-item" href="https://support-rhprofiler.zendesk.com/hc/fr/restricted?return_to=https%3A%2F%2Fsupport-rhprofiler.zendesk.com%2Fhc%2Ffr" target="_blank">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>Tickets</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logOut()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Déconnexion</span>
                </div>
              </a>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();" id="dropdownTodo">
              <i class="ft-list font-medium-3"></i><span class="notification badge badge-pill badge-danger">{{getCountToDo()}} </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
