import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MsgResult } from '../models/msgResult';

@Injectable()
export class AuthService {
  static user: any;
  static userId: string;
  static db: string;
  static passDate: number;
  static forcePass: boolean = false;
  private loggedIn = false;

  constructor(public router: Router, private http: HttpClient) {
  }

  SetUser(user) {
    AuthService.user = user;
    this.loggedIn = true;
  }

  logout() {
    AuthService.user = null;
    sessionStorage.clear();
    this.loggedIn = false;
    this.router.navigate(['/pages/login']);
  }

  isAuthenticated() {
    return this.loggedIn;
  }
}
