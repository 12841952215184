import * as CryptoJS from 'crypto-js';
import { Injectable } from "@angular/core";
import { sha256 } from "js-sha256";
import { AuthService } from '../auth/auth.service';

@Injectable()
export class EncryptDecryptService {

  getDecrypt(text): string {
    const test = CryptoJS.AES.decrypt(text, CryptoJS.enc.Hex.parse(sha256(AuthService.user.encryptKey)), {
      iv: CryptoJS.enc.Hex.parse(sha256(AuthService.user.encryptKey).substr(0, 32)),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString(CryptoJS.enc.Utf8);
    return test;
  }

  getEncrypt(text): string {
    const test = CryptoJS.AES.encrypt(text, CryptoJS.enc.Hex.parse(sha256(AuthService.user.encryptKey)), {
      iv: CryptoJS.enc.Hex.parse(sha256(AuthService.user.encryptKey).substr(0, 32)),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString();
    return test;
  }

}
