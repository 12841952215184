import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/page/home', title: 'Accueil', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], hasRight: 'accueil'
  },
  {
    path: '', title: 'Recrutements', icon: 'ft-layers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'recrutement',
    submenu: [
      {
        path: '/pages/offres', title: 'Offres', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'offres',
        submenu: []
      },
      {
        path: '/pages/offres-modeles', title: 'Offres Modèles', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'offresmodeles',
        submenu: []
      },
      {
        path: '', title: 'Créer', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'rcreer',
            submenu: [
              { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/ajouter_modele.asp', title: 'Une offre modèle', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], hasRight: 'rcmodele', },
              { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/ajouter.asp', title: 'Un recrutement', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'rcoffre', submenu: [] },
              { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/ajouter_cab.asp', title: 'Une mission', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'rcoffre_cab', submenu: [] },
            ]
      },
      {
        path: '', title: 'Gérer', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'rgerer',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi_modele.asp', title: 'Les offres modèles', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'rgmodele', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=enligne', title: 'Les offres actives', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'cv', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=programme', title: 'Les offres programmées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'cc', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=desactive', title: 'Les offres suspendues', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'cd', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=archive', title: 'Les offres archivées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'ce', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=enligne', title: 'Les missions actives', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'cv_cab', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=programme', title: 'Les missions programmées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'cc_cab', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=desactive', title: 'Les missions suspendues', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'cd_cab', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/offresemploi.asp?numpage=1&choix=archive', title: 'Les missions archivées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'ce_cab', submenu: [] },
        ]
      },
    ]
  },
  {
    path: '', title: 'Candidatures', icon: 'ft-inbox', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'd',
    submenu: [
      {
        path: '', title: 'Créer', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'da',
        submenu: [
          {
            path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/requete/ajouter.asp', title: 'Recherche approfondie', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'daa',
            submenu: []
          },
          {
            path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/requete/ajouter.asp?recherche=light', title: 'Recherche simplifiée', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', hasRight: 'dab',isExternalLink: false,
            submenu: []
          },
          {
            path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/requete/ajouter_dossier.asp', title: 'Dossier', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dac',
            submenu: []
          },
          {
            path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/requete/ajouter.asp?recherche=tri', title: 'Filtre sur les réponses', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', hasRight: 'dad',isExternalLink: false,
            submenu: []
          },
        ]
      },
      {
        path: '', title: 'Gérer', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'db', submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/menu.asp?recherche=oui', title: 'Vos recherches sauvegardées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dba', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/menu.asp?permanent=oui', title: 'Vos dossiers partagés', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dbb', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/menu.asp?personnel=oui', title: 'Vos dossiers personnels', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dbc', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/menu.asp?administratifs=oui', title: 'Vos dossiers administratifs', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dbd', submenu: [] },
        ]
      },
      {
        path: '', title: 'Réponses aux offres', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dc',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/reponseoffre.asp?choix=enligne', title: 'Actives', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dca', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/reponseoffre.asp?choix=archive', title: 'Archivées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dcb', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/reponseoffre.asp?choix=desactive', title: 'Suspendues', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dcc', submenu: [] },
        ]
      },
      {
        path: '', title: 'Ajouter un candidat', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dd',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/ajout_manuel_reponse_pj.asp', title: 'Sur un recrutement', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dda',submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/ajout_manuel_vivier_pj.asp', title: 'En banque de CV', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'ddb', submenu: [] },
        ]
      },
      {
        path: '', title: 'Relation Candidats', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'de',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/ajout_manuel_invitation.asp', title: 'Chasse : envoyer un e-mail', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'dea', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/ajout_manuel_negatif.asp', title: 'Refus sans enregistrer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'deb', submenu: [] },
        ]
      },
    ]
  },
  {
    path: '', title: 'Gestion Interne', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, hasRight: 'e',
    submenu: [
      {
        path: '', title: 'A faire', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'ea',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/outil/planning_recruteur_multiple/', title: 'Gestion du planning', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'eaa', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/tache_ajouter.asp', title: 'Créer une tâche', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'eab', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/emploi/tache_liste.asp', title: 'Vos tâches', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'eac', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/outil/topannonce/topannonce.asp', title: 'Gestion du Top annonces', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'ead', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/admin/stats_transfert.asp', title: 'Suivi des CV transférés', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'eae', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/outil/note_frais/editer_courrier_frais.asp', title: 'Note de frais candidats', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'eaf', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/outil/note_frais/editer_courrier_frais.asp?archive=oui', title: 'Note de frais archivées', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'eag', submenu: [] },
        ]
      },
    ]
  },
  {
    path: '', title: 'Ressources', icon: 'ft-image', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, hasRight: 'f',
    submenu: [
      {
        path: '', title: 'Sources de diffusion', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fa',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/source_ajout_jobpost.asp', title: 'Créer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'faa', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/source_modif_jobpost.asp', title: 'Visualiser/Modifier', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fab', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/source_ajout_multioffres_v6.asp', title: 'Affecter/Vérifier', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fac', submenu: [] },
        ]
      },
      {
        path: '', title: 'Groupe de sources', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fb',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/source_ajout_gr_jobpost.asp', title: 'Créer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fba', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/source_modif_gr_jobpost.asp', title: 'Visualiser/Modifier', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fbb', submenu: [] },
        ]
      },
      {
        path: '', title: 'Messages', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fc',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/module_message', title: 'Gestion des modèles', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'fca', submenu: [] },
        ]
      },
    ]
  },
  {
    path: '', title: 'Clients', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, hasRight: 'clients',
    submenu: [
      {
        path: '', title: 'Sociétés', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_societes',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/creation_de_societe.asp?num=1', title: 'Créer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_societes_creer', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/modification_de_societe.asp?num=1', title: 'Modifier', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_societes_modifier', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/visualisation_de_societe.asp?num=1', title: 'Visualiser', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_societes_visu', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/suppression_de_societe.asp?num=1', title: 'Supprimer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_societes_suppr', submenu: [] },
        ]
      },
      {
        path: '', title: 'Contacts', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_contacts',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/creation_de_contact_v6.asp?num=1', title: 'Créer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_contacts_creer', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/modification_de_contact.asp?num=1', title: 'Modifier', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_contacts_modifier', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/visualisation1_de_contact.asp?num=1', title: 'Visualiser', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_contacts_visu', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/suppression_de_contact.asp?num=1', title: 'Supprimer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_contacts_suppr', submenu: [] },
        ]
      },
      {
        path: '', title: 'Suivi', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_suivi',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/suivimission.asp', title: 'Missions par contact client', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'missions_contact_client', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/suivimission_client.asp', title: 'Missions par client', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'missions_client', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/suivicommercial.asp', title: 'Commercial par contact client', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'commercial_contact_client', submenu: [] },

          { path: 'https://applicatifs.rhprofiler.fr/groupe/cv3/requete/bureau/recrutesv2.asp?num=1&numpage=1', title: 'Intérimaires', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_suivi_inter', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/admin/stats_transfert_v2.asp?societe=-1&bloque=&resend=', title: 'Des CV transférés', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'clients_suivi_cv', submenu: [] },
        ]
      },
    ]
  },
  {
    path: '', title: 'Statistiques', icon: 'ft-bar-chart-2', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, hasRight: 'g',
    submenu: [
      {
        path: '', title: 'Banque de CV', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'ga',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/admin/statcandidats1.asp', title: 'Cartographie', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gaa', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/stat_connexion_site.asp', title: 'Connexions sur votre site', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gab', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/stat_depot_cv.asp', title: 'Qualité des dossiers', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gac', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/statmois.asp', title: 'Dépôt de candidatures', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gad', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/admin/statoffres_rendement_valider.asp?source=Indiff%E9rent&annee=' + new Date().getFullYear(), title: 'Performance des sources', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gae', submenu: [] },
        ]
      },
      {
        path: '', title: 'Annonces', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gb',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/stat_rep_bilan_social_v2.asp?recrute=oui', title: 'Bilan social des recrutés', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gba', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/stat_rep_bilan_social_v2.asp', title: 'Bilan social des candidatures', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbb', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/stat_chef_groupe.asp', title: 'Evolution des réponses', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbc', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/avance/suivi_recrutement_postes2.asp?mois=3', title: 'Entretiens et recrutés / poste', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbd', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/avance/suivi_recrutement_postes_2.asp?anrecrute=' + new Date().getFullYear(), title: 'Recrutements par poste', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbe', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/avance/suivi_recrutement_postes_2_saisonniers.asp?anrecrute' + new Date().getFullYear(), title: 'Recrutements saisonniers', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbf', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/avance/suivi_candidats_saisonniers.asp?anrecrute=' + new Date().getFullYear(), title: 'Candidats saisonniers', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbg', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/avance/suivi_recrutement5.asp?an=' + new Date().getFullYear(), title: 'Statistiques spécifiques', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gbh', submenu: [] },
        ]
      },
      {
        path: '', title: 'Suivi service RH', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gc',
        submenu: [
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/connexion_client.asp', title: 'Connexions', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gca',submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/fiche_utilisateur.asp', title: 'Historique des évènements', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gcb', submenu: [] },
          { path: 'https://applicatifs.rhprofiler.fr/groupe/statistiques/admin/nom_req.asp', title: 'Listes des dossiers', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, hasRight: 'gcc', submenu: [] },
        ]
      },
    ]
  },
];
